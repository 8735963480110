import classNames from 'classnames'
import React from 'react'
import Button from 'components/Button'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'

type Props = {
  children?: string
  onClick?: () => void
} & Component

export default function EnterpriseEligibleButton({
  children = 'Check eligibility',
  className,
  onClick,
  ...props
}: Props) {
  const globalContext = useGlobalContext()

  function handleClick() {
    if (onClick) onClick()

    if (globalContext.isAuthed) return

    window.open(
      'https://agebold017342.typeform.com/to/TwsNdcnf?utm_source=landing+page&utm_medium=web&utm_campaign=bold+eligibility+survey',
      '_blank'
    )
  }

  if (globalContext.isEnterprise) return null

  return (
    <Button
      {...props}
      to={globalContext.isAuthed ? paths.ENTERPRISE_ELIGIBLE : undefined}
      className={classNames('EnterpriseEligibleButton', className)}
      onClick={handleClick}>
      {children}
    </Button>
  )
}
